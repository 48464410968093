/**
 * This is the default i18n configuration items for biogroup apps
 * You can use different values in your app or extend them if
 * your app has different i18n requirements.
 */
export type SupportedLanguages = 'fr' | 'en'

export const supportedLngs: SupportedLanguages[] = ['fr', 'en']

export const fallbackLng = 'fr'

export const defaultNS = 'common'
